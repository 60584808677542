import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes, useNavigate, useLocation } from 'react-router-dom'
import { Home } from './components/Home'
import tw, { styled } from 'twin.macro'
import { Pricing } from './components/Pricing'
import { Customers } from './components/Customers'
import { Contact } from './components/Contact'
import { Expenses } from './components/Expenses'
import { Subscriptions } from './components/Subscriptions'
import { Claims } from './components/Claims'
import { BookDemoModalProvider, useBookDemoModal } from './context'
import { GetDemo } from './components/GetDemo'
import { useIsMobile } from './components/common/hooks'
import { CustomerCase } from './components/CustomerCase'
import { ForMerchants } from './components/ForMerchants'
import { Benefits } from './components/Benefits'
import { Intercom } from '@intercom/messenger-js-sdk'
import { INTERCOM_APP_ID } from './config/env'

const RedirectToLocale = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const locale = navigator.language

    let path = '/en' // Default to 'en'

    if (locale.startsWith('fi')) {
      path = '/fi'
    } else if (locale.startsWith('sv')) {
      path = '/sv'
    } else if (locale.startsWith('en')) {
      path = '/en'
    }

    navigate(path, { replace: true })
  }, [navigate])

  return null
}

type WrapperProps = {
  $isMobile?: boolean
}
export const Wrapper = styled.div<WrapperProps>`
  ${tw`w-[1200px] mx-auto `}
  ${({ $isMobile }: WrapperProps) => $isMobile && tw`w-full overflow-x-hidden`}
`

const ScrollToTop: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
      })
    }, 300)
  }, [location])

  return null
}

const AppRouter = () => {
  const { bookDemoModalOpen, setBookDemoModalOpen } = useBookDemoModal()
  const isMobile = useIsMobile()
  const [isMobileInitialized, setIsMobileInitialized] = useState(false)
  useEffect(() => {
    if (isMobile === undefined) {
      return
    }

    if (!isMobileInitialized) {
      setIsMobileInitialized(true)
      return
    }

    window.location.reload()
  }, [isMobile])
  useEffect(() => {
    // the exception for zevoy.com/merchants url - redirecting to the localised page
    const isDirectMerchants = window.location.pathname.split('/')[1] === 'merchants'
    if (isDirectMerchants) {
      const locale = navigator.language
      let prefix = 'en' // Default to 'en'

      if (locale.startsWith('fi')) {
        prefix = 'fi'
      } else if (locale.startsWith('sv')) {
        prefix = 'sv'
      } else if (locale.startsWith('en')) {
        prefix = 'en'
      }
      window.location.pathname = `/${prefix}/merchants`
      return
    }

    if (
      !window.location.pathname.startsWith('/en') &&
      !window.location.pathname.startsWith('/fi') &&
      !window.location.pathname.startsWith('/sv')
    ) {
      window.location.pathname = '/en'
    }
  }, [window.location.pathname])

  useEffect(() => {
    const cookiesAccepted = window.localStorage.getItem('cookies_accepted')
    if (
      window.cookiehub?.initialise &&
      cookiesAccepted !== 'all' &&
      cookiesAccepted !== 'necessary'
    ) {
      window.cookiehub.initialise()
      const allowAllCookiesPanel = document.querySelector(
        '.ch2 .ch2-dialog-actions .ch2-allow-all-btn',
      )
      const allowAllCookiesSettings = document.querySelector(
        '.ch2 .ch2-settings-tab-container .ch2-allow-all-btn',
      )
      const allowNecessaryCookies = document.querySelector('.ch2 .ch2-save-settings-btn')
      allowAllCookiesPanel?.addEventListener('click', () => {
        window.localStorage.setItem('cookies_accepted', 'all')
      })
      allowAllCookiesSettings?.addEventListener('click', () => {
        window.localStorage.setItem('cookies_accepted', 'all')
      })
      allowNecessaryCookies?.addEventListener('click', () => {
        window.localStorage.setItem('cookies_accepted', 'necessary')
      })
    }

    return () => {
      const allowAllCookiesPanel = document.querySelector(
        '.ch2 .ch2-dialog-actions .ch2-allow-all-btn',
      )
      const allowAllCookiesSettings = document.querySelector(
        '.ch2 .ch2-settings-tab-container .ch2-allow-all-btn',
      )
      const allowNecessaryCookies = document.querySelector('.ch2 .ch2-save-settings-btn')
      allowAllCookiesPanel?.removeEventListener('click', () => {
        window.localStorage.setItem('cookies_accepted', 'all')
      })
      allowAllCookiesSettings?.removeEventListener('click', () => {
        window.localStorage.setItem('cookies_accepted', 'all')
      })
      allowNecessaryCookies?.removeEventListener('click', () => {
        window.localStorage.setItem('cookies_accepted', 'necessary')
      })
    }
  }, [window.location.pathname, window.cookiehub])

  if (INTERCOM_APP_ID) {
    Intercom({
      app_id: INTERCOM_APP_ID,
      region: 'eu',
    })
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<RedirectToLocale />} />
          <Route path="/:locale" element={<Home />} />
          <Route path="/:locale/expenses" element={<Expenses />} />
          <Route path="/:locale/subscriptions" element={<Subscriptions />} />
          <Route path="/:locale/claims" element={<Claims />} />
          <Route path="/:locale/pricing" element={<Pricing />} />
          <Route path="/:locale/customers" element={<Customers />} />
          <Route path="/:locale/customers/:customer" element={<CustomerCase />} />
          <Route path="/:locale/contact" element={<Contact />} />
          <Route path="/:locale/merchants" element={<ForMerchants />} />
          <Route path="/:locale/benefits" element={<Benefits />} />
          <Route path="*" element={<RedirectToLocale />} />
        </Routes>
      </Router>
      {bookDemoModalOpen && (
        <GetDemo
          isMobile={isMobile}
          onClose={() => {
            setBookDemoModalOpen(false)
          }}
        />
      )}
    </>
  )
}
function App() {
  const isMobile = useIsMobile()
  return (
    <Wrapper $isMobile={isMobile}>
      <BookDemoModalProvider>
        <AppRouter />
      </BookDemoModalProvider>
    </Wrapper>
  )
}

export default App
